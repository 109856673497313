<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>品牌管理</a-breadcrumb-item>
            <a-breadcrumb-item>云品牌库</a-breadcrumb-item>
          </a-breadcrumb>
        </div>
        <div v-if="!visible" class="Enter-top-right">
          <!-- <a-button class="top-right-inpot" @click.prevent="showDrawer">
            企业品牌库
          </a-button> -->
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.visible = false
                this.Menushow = 0
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: hidden">
      <a-layout-content
        style="background-color: #fff; padding: 24px; margin: 0; overflow: auto"
      >
        <div v-if="modvisible" class="search-wrap">
          <a-table
            rowKey="id"
            :pagination="false"
            :columns="modcolumns"
            :data-source="nativelist"
            :rowClassName="rowClassName"
          >
          </a-table>
          <a-pagination
            v-model="models.current"
            :page-size="models.pageSize"
            show-quick-jumper
            :default-current="2"
            :total="models.total"
            @change="onpagemodel"
          />
        </div>
        <div v-else class="search-wrap">
          <div v-if="!visible">
            <div class="brand-screen">
              <a-form-item
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                label="业态"
              >
                <a-cascader
                  :field-names="{
                    label: 'name',
                    value: 'id',
                    children: 'children',
                  }"
                  style="width: 180px; margin-right: 15px"
                  :options="BusinessTypes"
                  placeholder="请选择业态"
                  v-model="businessTypeIds"
                  change-on-select
                  @change="onChangebus"
                />
              </a-form-item>
              <a-form-item
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                label="扩展区域"
              >
                <a-cascader
                  :field-names="{
                    label: 'title',
                    value: 'key',
                    children: 'children',
                  }"
                  :options="districtlist"
                  placeholder="请选择区域"
                  style="width: 180px; margin-right: 15px"
                  v-model="districtIds"
                  change-on-select
                  @change="onChangetype"
                />
              </a-form-item>
              <a-form-item
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                label="品牌名"
              >
                <a-input
                  style="width: 180px"
                  v-model="params.brand_name.value"
                  placeholder="请输入品牌名"
                />
              </a-form-item>
              <a-button
                type="primary"
                style="position: absolute; right: 350px"
                @click="onsearch"
              >
                搜索
              </a-button>
              <div
                style="position: absolute; right: 80px"
                class="brand-sort-right"
              >
                <div style="font-size: 16px">
                  已选<span style="color: orange">{{ checkStore.length }}</span
                  >个品牌
                </div>
                <a-button
                  @click="outtolocal"
                  type="primary"
                  style="margin-left: 15px"
                >
                  导入到本地品牌库
                </a-button>
              </div>
              <div
                style="position: absolute; right: 0"
                v-show="visible == false"
              >
                <a-icon
                  v-if="modvisible == true"
                  @click="
                    () => {
                      this.modvisible = false
                    }
                  "
                  style="
                    width: 30px;
                    margin-right: 5px;
                    cursor: pointer;
                    font-size: 25px;
                  "
                  type="rollback"
                />
                <a-button v-else type="primary" @click="showModal">
                  日志
                </a-button>
              </div>
            </div>
            <div class="brand-sort">
              <div class="brand-sort-left"></div>
            </div>
            <div class="brand-main">
              <div v-for="item in cloudlist" :key="item.id">
                <input
                  type="checkbox"
                  @click="onchecked(item.id)"
                  class="card-checkbok"
                  :value="item.id"
                  v-model="checkStore"
                />
                <div class="main-card-top" @click="onEditthing(item.id)">
                  <!-- <img :src="item.htmlUrl" alt="" /> -->

                  <img :src="item.logoPic" alt="" />
                  <div class="card-name">
                    {{ item.featuretag }}
                  </div>
                  <div class="card-name2">联系人: {{ item.linkmanLevel }}</div>
                </div>
                <div
                  @click="onEditthing(item.id)"
                  style="box-shadow: 0 4px 8px rgb(0 0 0 / 20%)"
                  class="main-card-bottom"
                >
                  <div class="card-bootom-big">{{ item.brandName }}</div>
                  <div class="card-bootom-small">
                    品牌定位:
                    <span class="card-bootom-text">{{
                      item.priceGrade == 2054
                        ? '高端'
                        : item.priceGrade == 2055
                        ? '中高端'
                        : '大众化'
                    }}</span>
                  </div>
                  <!-- <div class="card-bootom-small">
                    需求面积:
                    <span class="card-bootom-text"
                      >{{ item.mianJiMin }} -{{ item.mianJiMax }}平米</span
                    >
                  </div> -->
                  <div class="card-bootom-small">
                    {{ item.companyName }}
                  </div>
                </div>
              </div>
            </div>
            <a-pagination
              v-model="params.current"
              :page-size-options="pageSizeOptions"
              :page-size="params.pageSize"
              show-quick-jumper
              show-size-changer
              :default-current="2"
              :total="total"
              @change="onpagesize"
              @showSizeChange="onShowSizeChange"
            >
              <template slot="buildOptionText" slot-scope="props">
                <span v-if="props.value !== '50'">{{ props.value }}条/页</span>
                <span v-if="props.value === '50'">全部</span>
              </template>
            </a-pagination>
          </div>
          <div
            v-else
            style="height: 100%; justify-content: space-between"
            class="flex-d-row"
          >
            <a-modal
              title="查看联系人"
              :visible="contactvisible"
              :confirm-loading="confirmLoading"
              @ok="contactOk"
              @cancel="contactCancel"
            >
              <div style="text-align: center">
                <p style="margin-top: 15px">
                  是否要消耗次数查看联系方式! 日用量 :{{ numlist.dayUsed }}/{{
                    numlist.dayLimit
                  }}
                  套餐总量 :{{ numlist.customerUsed }}/{{
                    numlist.customerLimit
                  }}
                </p>
              </div>
            </a-modal>
            <div class="thing-main">
              <div class="thing-main-body">
                <div class="main-body-top flex-d-row">
                  <div class="main-body-logo">
                    <img
                      style="height: 100%; width: 80px; margin-top: 6px"
                      :src="datacontent.logoPic"
                      alt=""
                    />
                  </div>
                  <div
                    style="margin-left: 15px"
                    class="main-body-title flex-d-column"
                  >
                    <span class="title-big"
                      >{{ datacontent.brandName }}({{
                        datacontent.brandAlias
                      }})</span
                    >
                    <div>{{ datacontent.companyName }}</div>
                    <div style="width: 200px">
                      <span
                        v-if="
                          datacontent.othertag == '' ||
                          datacontent.othertag == null
                        "
                        style="
                          display: inline-block;
                          border: 1px solid #d9d9d9;
                          color: #36d36a;
                          padding: 2px 10px;
                        "
                      >
                        {{ datacontent.othertag }}
                      </span>
                    </div>
                  </div>
                </div>
                <div style="height: 610px" class="main-body-bottom flex-d-row">
                  <div class="bottom-lunbo">
                    <!-- <div
                      class="flex-d-row"
                      style="justify-content: center;align-items: center;height:100%;font-size:20px"
                      v-if="hotWayBrand == ''"
                    >
                      暂无图片
                    </div> -->
                    <div class="swiper_box">
                      <swiper
                        :options="swiperOptionTop"
                        style="height: 485px"
                        ref="swiperTop"
                        v-if="hotWayBrand.length"
                      >
                        <swiper-slide
                          class="slide-1"
                          v-for="(item, index) in hotWayBrand"
                          :key="index"
                        >
                          <img
                            :src="item"
                            style="height: 100%; width: 100%"
                            alt=""
                          />
                        </swiper-slide>
                      </swiper>
                      <swiper
                        style="margin: 20px 0 0 0px"
                        :options="swiperOptionThumbs"
                        class="gallery-thumbs"
                        ref="swiperThumbs"
                        v-if="hotWayBrand.length"
                      >
                        <swiper-slide
                          style="width: 100px; height: 100px"
                          v-for="(item, index) in hotWayBrand"
                          :key="index"
                        >
                          <img
                            style="width: 100%; height: 100%"
                            :src="item"
                            alt=""
                          />
                        </swiper-slide>
                      </swiper>
                      <div class="swiper-button-prev swiper-button-prev1"></div>
                      <div class="swiper-button-next swiper-button-next1"></div>
                    </div>
                  </div>
                  <div class="bottom-right-text flex-d-column">
                    <div
                      style="
                        align-items: center;
                        justify-content: space-between;
                      "
                      class="text-bigtitle flex-d-row"
                    >
                      <div class="lex-d-column-cent">
                        <div class="title-big">
                          {{ datacontent.businessTypeNames }}
                        </div>
                        <div style="margin-top: 8px">业态类别</div>
                      </div>
                      <div class="lex-d-column-cent">
                        <div class="title-big">
                          {{ datacontent.priceGrade }}
                        </div>
                        <div style="margin-top: 8px">品牌定位</div>
                      </div>
                    </div>
                    <div class="text-right-text flex-d-column">
                      <span class="right-text-name"
                        ><span class="names">创立时间:</span
                        ><span class="right-text-main">{{
                          datacontent.foundTime
                        }}</span></span
                      >
                      <span class="right-text-name"
                        ><span class="names">人均消费:</span
                        ><span
                          v-if="
                            datacontent.averagePriceMax == '' ||
                            datacontent.averagePriceMax == null
                          "
                          class="right-text-main"
                          >暂无</span
                        ><span v-else class="right-text-main"
                          >{{ datacontent.averagePriceMin }}-{{
                            datacontent.averagePriceMax
                          }}元</span
                        ></span
                      >
                      <span class="right-text-name"
                        ><span class="names">开店方式:</span
                        ><span class="right-text-main">{{
                          datacontent.brandtsztRemark
                        }}</span></span
                      >
                      <span class="right-text-name"
                        ><span class="names">合作期限:</span
                        ><span
                          v-if="
                            datacontent.cooperationTimeMax == '' ||
                            datacontent.cooperationTimeMax == null
                          "
                          class="right-text-main"
                          >{{ datacontent.cooperationTimeMin }}-{{
                            datacontent.cooperationTimeMax
                          }}年</span
                        >
                        <span v-else class="right-text-main"
                          >{{ datacontent.cooperationTimeMin }}-{{
                            datacontent.cooperationTimeMax
                          }}年</span
                        ></span
                      >
                      <span class="right-text-name"
                        ><span class="names">面积要求:</span
                        ><span class="right-text-main"
                          >{{ datacontent.mianJiMin }}-{{
                            datacontent.mianJiMax
                          }}平米</span
                        ></span
                      >
                      <span class="right-text-name"
                        ><span class="names">合作方式:</span
                        ><span class="right-text-main">{{
                          datacontent.brandtsztRemark
                        }}</span></span
                      >
                      <!-- <span class="right-text-name"
                        >品牌定位:<span class="right-text-main">{{
                          datacontent.priceGrade
                        }}</span></span
                      > -->
                      <span class="right-text-name"
                        ><span class="names">企业名称:</span
                        ><span class="right-text-main">{{
                          datacontent.companyName
                        }}</span></span
                      >
                    </div>
                    <div
                      style="
                        justify-content: space-between;
                        align-items: center;
                      "
                      class="text-bottom-btn flex-d-row"
                    >
                      <a-button
                        @click="thingMenu(3)"
                        style="width: 160px; height: 46px"
                        type="primary"
                        >查看联系方式
                      </a-button>

                      <a-button
                        @click="thingMenu(1)"
                        style="width: 160px; height: 46px"
                        >查看需求</a-button
                      >
                    </div>
                  </div>
                </div>
                <div style="margin-top: 30px">
                  <a-tabs
                    :activeKey="keys"
                    default-active-key="1"
                    @change="callback"
                  >
                    <a-tab-pane key="1" tab="扩展需求">
                      <div id="brands/cloud">
                        <div
                          style="
                            font-size: 16px;
                            height: 60px;
                            line-height: 60px;
                            padding-left: 13px;
                          "
                          class="title-big"
                        >
                          选址要求
                        </div>
                        <div
                          class="content-text"
                          v-html="datacontent.projectcondition"
                        ></div>
                      </div>
                      <div>
                        <div
                          style="
                            font-size: 16px;
                            height: 60px;
                            line-height: 60px;
                            padding-left: 13px;
                          "
                          class="title-big"
                        >
                          其他要求
                        </div>
                        <div
                          class="content-text"
                          v-html="datacontent.projectcondition"
                        ></div>
                      </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="品牌概况" force-render>
                      <div>
                        <div
                          style="
                            font-size: 16px;
                            height: 60px;
                            line-height: 60px;
                            padding-left: 13px;
                          "
                          class="title-big"
                        >
                          品牌概况
                        </div>
                        <div
                          style="padding-left: 30px"
                          class="content-text"
                          v-html="datacontent.hotWayBrandId"
                        ></div>
                      </div>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="联系方式">
                      <div id="moblies">
                        <div
                          style="
                            font-size: 16px;
                            height: 60px;
                            line-height: 60px;
                            padding-left: 13px;
                          "
                          class="title-big"
                        >
                          联系方式
                        </div>
                      </div>
                      <div
                        style="padding-left: 30px"
                        class="main-body-bottom flex-d-row"
                      >
                        <div
                          style="flex-wrap: wrap; width: 100%"
                          class="flex-d-row"
                        >
                          <div
                            v-for="item in contactlist"
                            :key="item.id"
                            :style="
                              contactlist.length > 3
                                ? 'justify-content: space-between;'
                                : ''
                            "
                            class="body-bottom-card flex-d-column"
                          >
                            <div>
                              <span class="title-big">{{ item.name }}</span
                              ><span class="gray"> / {{ item.zhiwei }}</span>
                            </div>
                            <div>
                              <span class="gray" style="font-size: 16px">{{
                                item.company | text
                              }}</span>
                            </div>
                            <div>
                              <a-icon
                                style="color: #677ce9"
                                class="card-icon"
                                type="phone"
                              />
                              <span>{{
                                item.moblie == '' ? '暂无' : item.moblie
                              }}</span>
                            </div>
                            <div>
                              <a-icon
                                style="color: #677ce9"
                                class="card-icon"
                                type="phone"
                              />
                              <span
                                >座机号码：{{
                                  item.tel == '' ? '暂无' : item.tel
                                }}</span
                              >
                            </div>
                            <div>
                              <a-icon
                                style="color: #09b2be"
                                class="card-icon"
                                type="mail"
                              />
                              <span>{{
                                item.email == '' ? '暂无' : item.email
                              }}</span>
                            </div>
                            <div>
                              <a-icon
                                style="color: #df4b08"
                                class="card-icon"
                                type="environment"
                              /><span
                                >拓展地区：{{
                                  item.area == '' ? '暂无' : item.area
                                }}</span
                              >
                            </div>
                            <div
                              style="
                                position: absolute;
                                bottom: 10px;
                                right: 10px;
                                font-size: 14px;
                                cursor: pointer;
                              "
                              @click="onthing"
                            >
                              查看
                            </div>
                          </div>
                        </div>
                      </div>
                    </a-tab-pane>
                  </a-tabs>
                </div>
              </div>
            </div>
            <!-- <div class="thing-menu">
              <span :class="Menushow == 0 ? 'menu' : 'me'" @click="thingMenu(0)"
                >品牌概述</span
              >
              <span :class="Menushow == 1 ? 'menu' : 'me'" @click="thingMenu(1)"
                >品牌简介</span
              >
              <span :class="Menushow == 2 ? 'menu' : 'me'" @click="thingMenu(2)"
                >选址要求</span
              >
              <span :class="Menushow == 4 ? 'menu' : 'me'" @click="thingMenu(4)"
                >其他要求</span
              >
              <span :class="Menushow == 3 ? 'menu' : 'me'" @click="thingMenu(3)"
                >联系方式</span
              >
            </div> -->
          </div>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
const modcolumns = [
  {
    title: '日期',
    dataIndex: 'brandName',
  },
  {
    title: '时间',
    dataIndex: 'companyName',
  },
  {
    title: '查看品牌',
    dataIndex: 'listIndustryType3',
  },
  {
    title: '查看账号',
    dataIndex: 'mianJiMax',
  },
]
// const option = [];
import http from '../../../http'
export default {
  data() {
    return {
      keys: '1',
      swiperOptionTop: {
        // spaceBetween:10,
        loop: true,
        loopedSlides: 4,
        // zoom: true,//双击放大
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        observer: true,
        observeParents: false,
        on: {},
        grabCursor: true, // 抓手
      },
      swiperOptionThumbs: {
        loop: true, //开启无限轮播
        slidesPerView: 4, //显示几个图片
        spaceBetween: 10, //小图之间得距离
        direction: 'horizontal',
        // centeredSlides: true, // 设置活动块居中
        grabCursor: true, // 抓手,
        slideToClickedSlide: true,
        watchSlidesVisibility: true, // 防止不可点击
      },
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      modcolumns,
      datacontent: {},
      hotWayBrand: [],
      pageSizeOptions: ['14', '28', '42', '56', '70'],
      //   options: option,
      contactvisible: false,
      visibles: false,
      Menushow: 0,
      visible: false,
      modvisible: false,
      plainOptions: [],
      checkedList: [],
      BusinessTypes: [],
      params: {
        current: 1,
        pageSize: 14,
        business_type_ids: {
          value: '',
          op: '%like%',
        },
        city_name: {
          value: '',
          op: '%like%',
        },
        brand_name: {
          value: '',
          op: '%like%',
        },
      },
      total: 0,
      models: {
        current: 1,
        pageSize: 12,
        total: 0,
      },
      numlist: {
        customerLimit: 0,
        customerUsed: 0,
        dayLimit: 0,
        dayUsed: 0,
      },
      cloudlist: [],
      selectedRowKey: [],
      checked: false,
      contactlist: [],
      checkStore: [],
      districtlist: [],
      Id: '',
      businessTypeIds: '',
      districtIds: [],
      form: {
        id: '',
        superiorId: '',
        permissions: '新增',
        path: '',
        name: '',
        type: 0,
        state: '',
      },
    }
  },
  updated() {
    this.$nextTick(() => {
      // 实现swiper双向控制 swiperTop
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
      //   console.log(this.$refs.swiperThumbs.swiper);
    })
  },

  filters: {
    text(value) {
      if (!value) return ''
      if (value.length > 15) {
        return value.slice(0, 15) + '...'
      }
      return value
    },
  },
  methods: {
    onsearch() {
      this.params.current = 1
      this.getcloud()
    },
    onChangebus(e) {
      this.params.business_type_ids.value = e[e.length - 1]
    },
    onChangetype(e) {
      this.params.city_name.value = e[e.length - 1]
    },
    onShowSizeChange(current, pageSize) {
      this.params.pageSize = pageSize
      this.timer()
    },
    outtolocal() {
      this.deletecloud(this.checkStore)
    },
    callback(e) {
      this.keys = e
    },
    thingMenu(e) {
      //   console.log(e);
      this.keys = e + ''
    },
    showModal() {
      this.modvisible = true
    },
    onthing() {
      this.getcloudusage()
      this.getcloudcheck(this.form.id)
    },
    contactOk() {
      this.getcloudwatch(this.form.id, false)
      this.contactvisible = false
    },
    contactCancel() {
      this.contactvisible = false
    },
    onclose() {
      this.visibles = false
    },
    // 编辑
    onEditthing(item) {
      //   console.log(item);
      this.form.id = item
      this.visible = true
      this.getcloudId(item)
      this.getcloudcontact(item)
    },
    onpagesize(e) {
      this.params.current = e
      this.getcloud()
    },
    onpagemodel(e) {
      this.models.current = e
      //   this.getcloud();
    },
    //改变状态
    onchangestate(e) {
      //   console.log(e);
      if (e.state == 1) {
        this.form.state = 0
      } else if (e.state == 0) {
        this.form.state = 1
      }
      this.$message.success('操作成功')
      this.stateModule(e.id, this.form.state)
    },
    async getcloudcheck(id) {
      try {
        const res = await http.getcloudcheck(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          let that = this
          if (data.can == false) {
            that.contactvisible = true
          } else {
            that.getcloudwatch(that.form.id, data.can)
            //直接查看
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getcloudwatch(id, check) {
      try {
        const res = await http.getcloudwatch(id, check)
        const { success, data, msg } = res.data
        if (success) {
          //   console.log(data);
          this.contactlist = data.contacts
        } else {
          this.$message.error(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getcloudcontact(id) {
      try {
        const res = await http.getcloudcontact(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.contactlist = data.contacts
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getcloudusage() {
      try {
        const res = await http.getcloudusage()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.numlist = data
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getcloud() {
      try {
        const res = await http.getcloud(this.params)
        const { success, data } = res.data
        if (success) {
          this.cloudlist = data.brands.records
          this.total = data.brands.total
          //   console.log(data);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    HTMLDecode(text) {
      var temp = document.createElement('div')
      temp.innerHTML = text
      var output = temp.innerText || temp.textContent
      temp = null
      return output
    },
    async getcloudId(id) {
      this.hotWayBrand = []
      try {
        const res = await http.getcloudId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          data.brand.projectcondition = this.HTMLDecode(
            data.brand.projectcondition
          )
          this.datacontent = data.brand
          if (data.brand.hotWayBrand == '') {
            this.hotWayBrand = []
          } else {
            this.hotWayBrand = data.brand.hotWayBrand.split('|')

            // console.log(this.hotWayBrand);
          }
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async getdistrict2() {
      const res = await http.getdistrict2()
      const { success, data } = res.data
      if (success) {
        // console.log(data);
        this.districtlist = data.districts
        this.districtlist.forEach(item => {
          if (item.children == '') {
            item.children = undefined
          }
        })
        // console.log(this.districtlist);
      }
    },
    async deletecloud(ids) {
      try {
        const res = await http.deletecloud(ids)
        const { success, msg } = res.data
        if (success) {
          this.checkStore = []
          this.$message.success('导入成功')
          //   console.log(data);
        } else {
          this.$message.success(msg)
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    async BusinessTypelist() {
      try {
        const res = await http.BusinessTypelist()
        const { success, data } = res.data
        if (success) {
          this.BusinessTypes = data.businessTypes
          this.BusinessTypes.forEach(item => {
            item.children.forEach(item => {
              if (item.children == '') {
                item.children = undefined
              } else {
                item.children.forEach(item => {
                  if (item.children == '') {
                    item.children = undefined
                  } else {
                    item.children.forEach(item => {
                      if (item.children == '') {
                        item.children = undefined
                      }
                    })
                  }
                })
              }
            })
          })
          //   console.log(data.businessTypes);
        }
      } catch (ex) {
        console.log('error:', ex)
      }
    },
    onchecked(id) {
      //   console.log(id);
      let index = this.checkStore.findIndex(item => item == id)
      if (index == -1) {
        this.checkstorelist(id)
      } else {
        this.checkStores.splice(index, 1)
      }
    },
    timer() {
      return setTimeout(() => {
        this.getcloud()
      }, 100)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    handleChange(value) {
      console.log(value)
    },
  },
  mounted() {
    this.getcloud()
    this.BusinessTypelist()
    this.getdistrict2()
  },
  computed: {
    checkAll: {
      get() {
        return this.checkStore.length === this.cloudlist.length
      },
      set(val) {
        if (val) {
          this.checkStore = []
          this.cloudlist.forEach(cloudlist => {
            if (cloudlist.state == 1 || cloudlist.rentState == 1) {
              console.log()
            } else {
              this.checkStore.push(cloudlist.id)
            }
          })
        } else {
          this.checkStore = []
        }
      },
    },
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  min-width: 1557px;
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  padding: 10px 0 6px;
  padding-bottom: 80px;
  margin-bottom: 15px;
}
.gray {
  color: #aeaeae;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.flex-d-column {
  display: flex;
  flex-direction: column;
}
.flex-d-column-cent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-big {
  color: #000;
  font-size: 20px;
  font-weight: bold;
}

.ant-pagination {
  position: absolute;
  right: 40px;
  bottom: 25px;
}
.ant-layout {
  height: 100%;
}
.Enter-top {
  display: flex;
  padding: 0 24px;
  flex-direction: row;
  justify-content: space-between;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
::v-deep .content-text > p {
  color: #333;
  line-height: 1.7;
}
.brand-screen {
  padding: 0 0;
  height: 50px;
  line-height: 50px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .screen-text > i {
    font-size: 16px;
    margin-left: 5px;
    color: #999;
  }
}
.screen-text {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.screen-text > span {
  font-size: 16px;
  font-weight: 600;
}
.brand-sort {
  padding: 0 30px;
  height: 50px;
  line-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #edeef0;

  .brand-sort-left {
    width: 750px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.lex-d-column-cent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.brand-sort-right {
  width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
::v-deep .ant-tabs-nav .ant-tabs-tab {
  font-size: 16px;
}
.thing-main {
  width: 100%;
  height: 100%;
  .thing-main-body {
    padding-right: 24px;
    width: 1300px;
    margin: auto;
    .main-body-top {
      height: 110px;
      padding: 10px 0;
      .main-body-logo {
        height: 80px;
      }
      .main-body-title {
        justify-content: space-around;
      }
    }
    .main-body-bottom {
      .bottom-lunbo {
        width: 47%;
        max-width: 650px;
        height: 610px;
        position: relative;
      }
      .bottom-right-text {
        padding: 0 50px 0 80px;
        width: 50%;
        .text-bigtitle {
          width: 100%;
          color: #111;
        }
        .text-right-text {
          justify-content: space-around;
          height: 340px;
          padding-bottom: 15px;
          margin-top: 25px;
          border-bottom: 1px solid #d9d9d9;
          .right-text-name {
            color: #999;
            font-size: 15px;
          }
          .right-text-main {
            color: #333;
            font-size: 16px;
            font-weight: 500;
            margin-left: 8px;
          }
        }
        .text-bottom-btn {
          width: 100%;
          height: 50px;
          margin-top: 105px;
        }
      }
    }
  }
}
.menu {
  background-color: #dae6ff;
}
.me {
  background-color: #fff;
}
.thing-menu {
  width: 155px;
  height: 100%;
  border-left: 1px solid #eaeaea;
  margin-top: 105px;
}
.thing-menu > span {
  width: 100%;
  height: 40px;
  display: block;
  text-align: center;
  line-height: 40px;
}
.thing-menu > span:hover {
  background-color: #dae6ff;
}
.body-bottom-card {
  overflow: hidden;
  padding: 10px 15px;
  width: calc(25% - 10px);
  margin-bottom: 10px;
  margin-right: 10px;
  height: 200px;
  border: 1px solid #999;
  border-radius: 10px;
  position: relative;
}
.card-icon {
  width: 30px;
  font-size: 17px;
  height: 30px;
  line-height: 35px;
  text-align: center;
  background-color: rgba(200, 200, 200, 0.3);
  border-radius: 50%;
  margin-right: 10px;
}

.brand-main {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.brand-main > div {
  height: 300px;
  padding: 10px;
  display: flex;

  position: relative;
  flex-direction: column;
  .main-card-top {
    height: 60%;
    border: 1px solid #d9d9d9;
    cursor: pointer;
  }
  .main-card-top > img {
    width: 100%;
    height: 100%;
  }
  .main-card-bottom {
    cursor: pointer;
    padding-left: 6px;
    display: flex;
    font-size: 12px;
    font-weight: 600;
    flex-direction: column;
    justify-content: space-around;
    height: 40%;
    .card-bootom-big {
      font-size: 14px;
      font-weight: 600;
    }
    .card-bootom-text {
      font-size: 11px;
      font-weight: 400;
      color: #999;
    }
  }
}
::v-deep .swiper-button-next {
  background-image: url('../../../assets/arrow-right-bold.png');
  //   width: 30px;
  //   height: 45px;
  //   background-color: rgba(200, 200, 200, 0.3);
}
::v-deep .swiper-button-next:hover {
  background-image: url('../../../assets/arrow-right-bold-f.png');
}
::v-deep .swiper-button-prev:hover {
  background-image: url('../../../assets/arrow-left-bold-f.png');
}
::v-deep .swiper-button-prev {
  background-image: url('../../../assets/arrow-left-bold.png');
  //   width: 30px;
  //   height: 45px;
  //   background-color: rgba(200, 200, 200, 0.3);
}

.left-arror {
  position: absolute;
  left: 0;
  bottom: 85px;
}
.right-arror {
  position: absolute;
  right: 0;
  bottom: 85px;
}
.cloud-title-one,
.cloud-title-two {
  height: 35px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  line-height: 35px;
  border-bottom: 3px solid orange;
}
::v-deep .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 0;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
.ant-form-item {
  margin-bottom: 0;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
::v-deep .ant-checkbox-wrapper > span {
  font-size: 15px;
  font-weight: 600;
}
.names {
  display: inline-block;
  width: 70px;
}
.card-checkbok,
.card-name {
  position: absolute;
  top: 15px;
}

.card-name2 {
  position: absolute;
  bottom: 125px;
  right: 15px;
}
.card-checkbok {
  left: 15px;
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
}
.input-check {
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  margin-right: 3px;
  cursor: pointer;
}
.card-name {
  background: rgba(12, 12, 12, 0.2);
  width: 100px;
  height: 20px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  right: 15px;
}
.card-name2 {
  background: rgba(12, 12, 12, 0.2);
  width: 100px;
  height: 20px;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
.brand-main > div {
  width: 14%;
  margin-left: 0.26%;
}
::v-deep .ant-anchor-ink::before {
  width: 0;
}
::v-deep .ant-anchor-link {
  padding: 0;
}
.swiper-button-prev,
.swiper-button-next {
  top: 41%;
}
// .brand-main > div {
//   width: 16.5%;
// }
</style>
